import { OwnerTypeEnum } from 'modules/owners/types/OwnerTypeEnum';
import { OwnerStatusIdEnum } from 'modules/owners/store/owners/types';
import { IOwnerCard } from 'modules/owners/store/ownersCards/types';

export interface IOwner {
  agentId?: number;
  ownerId: number;
  status: IOwnerStatus;
  isAgent: OwnerTypeEnum;
  fio: string;
  chatId: number;
  limitIn: number;
  limitOut: number;
  bith: string;
  city: string;
  agentCommission?: number;
  accountSbp?: string;
  bankSbp?: string;
  mainPhone?: string;
  secondaryPhone?: string;
  isDeposit: boolean;
  tgNick?: string;
  ownerCommissions: IOwnerCommission[];
  amount?: number;
  card: IOwnerCard;
  cardNumber: string;
  //TODO: Исправить ошибку, *comming* -> *coming*
  incommingTransactionsIsAllowed: boolean;
  outcommingTransactionsIsAllowed: boolean;
  /** @deprecated */
  accountNumber?: string;
  /** @deprecated */
  bankId?: number;
  outcomingTransactionsInMonth?: number;
  pingInterval: number;
  providerCommission?: number;
  imei: string;
  pingStatus: boolean;
  disputesLimit: number;
  trustLimit: number;
  priority: number;
  balancePlanned?: number;
  balanceActual?: number;
  outcomingOrdersPriority: number;
  ping: boolean;
  linkedBy?: OwnerLinkedByEnum;
  login?: string;
  password?: string;
  goip: string;
  isAutoAssigmentEnabled: boolean;
  isSbpEnabled: boolean;
  trusted: boolean;
}

export interface IBindedOwner extends IOwner {
  receiptUrls?: string[];
}

export interface IOwnerGoip {
  name: string;
  channelId: number;
}

export enum OwnerLinkedByEnum {
  OPERATOR = 1,
  OUT_AUTO_ASSIGMENT = 2,
  SYSTEM = 3,
}

export interface IOwnerStatus {
  id: OwnerStatusIdEnum;
  name: string;
}

export interface IOwnerCommission {
  provider: {
    id: number;
    name: string;
    image: string;
  };
  in: number;
  out: number;
}
