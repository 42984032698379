export function maskPhoneNumber(phone: string) {
  return phone
    .split('')
    .map((char, index) => {
      if (index > phone.length - 3) {
        return '*';
      }

      return char;
    })
    .join('');
}
