import { FormFieldSchema } from 'components/UnifiedForm/types/fieldsSchema.interface';
import { useBankAccountsTypesFromServer, useBanksFromServer, useOwnersFromServer } from 'hooks/useDataFromServer';
import { CreateBankAccountParams } from 'modules/bankAccounts/store/types/createBankAccountParams.interface';

export const fieldsSchema: FormFieldSchema<CreateBankAccountParams>[] = [
  {
    name: 'ownerId',
    type: 'lazy-select',
    placeholder: 'Владелец',
    useDataHook: useOwnersFromServer,
    dataTransformer: (owner) => ({ label: `${owner.name} [${owner.id}]`, value: owner.id }),
    cacheKey: 'owner-select',
  },
  {
    name: 'bankId',
    type: 'lazy-select',
    placeholder: 'Банк',
    useDataHook: useBanksFromServer,
    cacheKey: 'provider-select',
  },
  {
    name: 'accountNumber',
    type: 'input',
    addonBefore: 'Номер счета',
    placeholder: '00000000000000000000',
  },
  {
    name: 'bic',
    type: 'input',
    addonBefore: 'БИК',
    placeholder: '000000000',
  },
  {
    name: 'phone',
    type: 'input',
    addonBefore: 'Номер телефона',
    placeholder: '+7 000 000 0000',
  },
  {
    name: 'limitMinIn',
    type: 'input',
    addonBefore: 'Мин. сумма вх. платежа',
  },
  {
    name: 'type',
    type: 'lazy-radio',
    title: 'Тип счета',
    optionType: 'button',
    cacheKey: 'bankAccountsTypes-radio',
    className: 'mb-0',
    useDataHook: useBankAccountsTypesFromServer,
    dataTransformer: (item) => ({ label: item.name, value: item.id }),
  },
];
