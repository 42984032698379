import React, { useEffect, useState } from 'react';

import { App, Button, Drawer } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { PlusOutlined } from '@ant-design/icons';

import { useAppDispatch } from 'hooks/useAppDispatch.hook';

import { createBankAccount } from 'modules/bankAccounts/store';
import { CreateBankAccountParams } from 'modules/bankAccounts/store/types/createBankAccountParams.interface';

import { ProtectedComponent } from 'policies/components/ProtectedComponent';
import { permissions } from 'policies/permissions';

import { fieldsSchema } from './fields';
import { UnifiedForm } from 'components/UnifiedForm';

interface Props {}

export const BankAccountsListCreateDrawer: React.FC<Props> = () => {
  const [form] = useForm<CreateBankAccountParams>();

  const [isShown, setIsShown] = useState<boolean>(false);

  const { message } = App.useApp();

  const dispatch = useAppDispatch();

  useEffect(() => {
    form.resetFields();
  }, [isShown]);

  async function handleSubmitPress() {
    try {
      await dispatch(createBankAccount(form.getFieldsValue())).unwrap();

      message.success('Счет владельца успешно создан');
      setIsShown(false);
    } catch (error) {
      message.error('Не удалось создать счет владельца.');
    }
  }

  function handleClose() {
    form.resetFields();
    setIsShown(false);
  }

  return (
    <>
      <ProtectedComponent requiredPermissions={[permissions.bankAccount.create]} fallbackComponent={<div />}>
        <Button icon={<PlusOutlined />} shape="round" type="primary" onClick={() => setIsShown(true)}>
          Создать
        </Button>
      </ProtectedComponent>
      <Drawer open={isShown} title="Создание счёта" onClose={handleClose}>
        <UnifiedForm
          form={form}
          className="flex flex-col h-full"
          fields={fieldsSchema}
          onSubmitClick={handleSubmitPress}
          submitButtonTitle="Добавить счёт"
          isSubmitButtonAtBottom
        />
      </Drawer>
    </>
  );
};
