import React, { FC, useEffect, useState } from 'react';
import { Button, App, Drawer } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Filters } from 'components/Filters';
import { useMemoFilters } from 'hooks/useMemoFilters/useMemoFilters';
import { ProtectedComponent } from 'policies/components/ProtectedComponent';

import { filtersSchema } from './filters';

import { MergeBanks } from 'modules/banks/components/MergeBanks/MergeBanks';
import { BanksPageTable } from 'modules/banks/pages/list/components/Table/BanksPageTable';

import { fetchBanks, setPagination } from 'modules/banks/store/banks/actions';
import { IFetchBanksParams } from 'modules/banks/store/banks/types';

import { permissions } from 'policies/permissions';
import { Pagination } from 'components/Pagination';
import { useAppDispatch } from 'hooks/useAppDispatch.hook';
import { useAppSelector } from 'hooks/useAppSelector.hook';
import { UpdateBankForm } from 'modules/banks/components/UpdateBankForm/UpdateBankForm';
import { CreateBankForm } from 'modules/banks/components/CreateBankForm/CreateBankForm';
import { BanksPageActions } from 'modules/banks/pages/list/components/Actions/BanksPageActions';

export const BanksPage: FC = () => {
  const { memoFilters, setMemoFilters } = useMemoFilters<IFetchBanksParams>();

  const { message } = App.useApp();

  const [isCreateBankShown, setIsCreateBankShown] = useState<boolean>(false);
  const [isMergeBanksShown, setIsMergeBanksShown] = useState<boolean>(false);
  const [isSelectColumnShown, setIsSelectColumnShown] = useState(false);
  const [bankToUpdateId, setBankToUpdateId] = useState<number>();

  const dispatch = useAppDispatch();

  const {
    pagination,
    pagination: { perPage, page },
    isLoading,
  } = useAppSelector((state) => state.banks);

  useEffect(() => {
    fetch();
  }, [memoFilters, page, perPage]);

  async function fetch() {
    try {
      await dispatch(fetchBanks({ ...memoFilters, page, perPage }));
    } catch {
      message.error('Не удалось получить список банков');
    }
  }

  function handlePaginationChange(page: number, perPage: number) {
    dispatch(setPagination(page, perPage));
  }

  function handleMergeBanksClose(): void {
    setIsMergeBanksShown(false);
  }

  function handleMergeBanks() {
    setIsMergeBanksShown(true);
  }

  function handleShowColumnSelectBanks() {
    setIsSelectColumnShown(!isSelectColumnShown);
  }

  return (
    <>
      <Drawer
        open={!!bankToUpdateId}
        title="Редактирование банка"
        onClose={() => setBankToUpdateId(undefined)}
        size="large"
      >
        {bankToUpdateId && (
          <UpdateBankForm
            bankId={bankToUpdateId}
            onSubmit={async () => {
              setBankToUpdateId(undefined);
              await fetch();
            }}
          />
        )}
      </Drawer>
      <Drawer title="Cоздание банка" open={isCreateBankShown} onClose={() => setIsCreateBankShown(false)} size="large">
        <CreateBankForm
          onSubmit={() => {
            fetch();
            setIsCreateBankShown(false);
          }}
        />
      </Drawer>
      <Drawer
        open={isMergeBanksShown}
        title="Действие: Объединение банков"
        onClose={() => setIsMergeBanksShown(false)}
        size="large"
      >
        <MergeBanks onClose={handleMergeBanksClose} />
      </Drawer>
      <Filters
        headerTitle="Банки"
        filters={filtersSchema}
        value={memoFilters}
        onSubmit={setMemoFilters}
        extraHeaderButtons={() => (
          <ProtectedComponent requiredPermissions={[permissions.bank.create]} fallbackComponent={<div />}>
            <Button icon={<PlusOutlined />} shape="round" type="primary" onClick={() => setIsCreateBankShown(true)}>
              Добавить
            </Button>
          </ProtectedComponent>
        )}
      />
      <BanksPageActions onSelectBanksClick={handleShowColumnSelectBanks} onMergeBanksClick={handleMergeBanks} />
      <BanksPageTable
        onBankEditPress={(bank) => setBankToUpdateId(bank.id)}
        isSelectColumnShown={isSelectColumnShown}
      />
      <Pagination pagination={pagination} isLoading={isLoading} onPaginationChange={handlePaginationChange} />
    </>
  );
};
