import React, { FC, useState } from 'react';
import { Form, Button, Spin, Select, Space, Tooltip, Row, App } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { CreateBankFormTable } from 'modules/banks/components/CreateBankForm/components/Table/CreateBankFormTable';
import { Input } from 'components/Input';

import { ProtectedComponent } from 'policies/components/ProtectedComponent';

import { binProviders } from 'modules/banks/components/CreateBankForm/constants';
import { permissions } from 'policies/permissions';
import { UpdateBankForm } from 'modules/banks/components/UpdateBankForm/UpdateBankForm';
import { useCreateBankFromBinRequest } from 'modules/banks/hooks/useCreateBankFromBinRequest.hook';
import { CreateBankFromBinRequestParams } from 'modules/banks/api/createBankFromBin.request';
import { Bank } from 'modules/banks/types/bank.interface';

interface Props {
  onSubmit: () => void;
}

export const CreateBankForm: FC<Props> = ({ onSubmit }) => {
  const [form] = useForm<CreateBankFromBinRequestParams>();

  const { message } = App.useApp();

  const { createBankFromBin, isCreatingBankFromBin } = useCreateBankFromBinRequest();

  const [selectedOption, setSelectedOption] = useState(binProviders[0].value);
  const [isUpdateFormShown, setIsUpdateFormShown] = useState<boolean>(false);
  const [createdBank, setCreatedBank] = useState<Bank>();

  async function handleSearch(): Promise<void> {
    try {
      const filteredNumbers = [form.getFieldValue('bin1'), form.getFieldValue('bin2'), form.getFieldValue('bin3')]
        .filter((item) => !isNaN(item) && item)
        .map((item) => Number(item));

      const values = {
        bins: filteredNumbers,
        source: selectedOption,
      };

      const bank = await createBankFromBin(values);
      setCreatedBank(bank);

      message.success('Банк успешно добавлен!');
      onSubmit();
    } catch (error) {
      message.error('Произошла ошибка');
    }
  }

  function handleBinSourceChange(value: React.SetStateAction<string>) {
    setSelectedOption(value);
  }

  if (isUpdateFormShown && createdBank) {
    return <UpdateBankForm bankId={createdBank.id} onSubmit={() => null} />;
  }

  return (
    <Spin spinning={isCreatingBankFromBin}>
      <Form form={form}>
        <Form.Item
          name="bin1"
          rules={[
            {
              pattern: /^[0-9]{6}$/,
              message: 'BIN должен состоять из 6 цифр',
            },
          ]}
        >
          <Input className="mb-2" addonBefore="BIN 1 банка" required />
        </Form.Item>
        <Form.Item
          name="bin2"
          rules={[
            {
              pattern: /^[0-9]{6}$/,
              message: 'BIN должен состоять из 6 цифр>',
            },
          ]}
        >
          <Input className="mb-2" addonBefore="BIN 2 банка" required={false} />
        </Form.Item>
        <Form.Item
          name="bin3"
          rules={[
            {
              pattern: /^[0-9]{6}$/,
              message: 'BIN должен состоять из 6 цифр>',
            },
          ]}
        >
          <Input className="mb-2" addonBefore="BIN 3 банка" required={false} />
        </Form.Item>
        <div className="flex items-center mb-2 ml-3">
          <label className="w-48">Источник</label>
          <Select
            className="w-full"
            value={selectedOption}
            options={binProviders}
            allowClear
            onChange={handleBinSourceChange}
          />
        </div>
        <Button className="mt-5 mb-5" type="primary" onClick={handleSearch}>
          Создать
        </Button>
      </Form>
      {createdBank && (
        <>
          <CreateBankFormTable bank={createdBank} />
          <Row justify="space-between">
            <Space>
              <ProtectedComponent requiredPermissions={[permissions.bank.update]}>
                <Tooltip title="Редактировать банк">
                  <Button type="primary" onClick={() => setIsUpdateFormShown(true)}>
                    Редактировать
                  </Button>
                </Tooltip>
              </ProtectedComponent>
            </Space>
          </Row>
        </>
      )}
    </Spin>
  );
};
