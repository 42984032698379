import React, { useMemo } from 'react';

import { Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';

import {
  RejectedTransaction,
  RejectedTransactionBank,
  RejectedTransactionMerchant,
} from 'modules/rejectedTransactions/types/rejectedTransaction.interface';
import dayjs from 'dayjs';
import { ORDER_DATETIME_STRING_FORMAT } from 'constants/dates';
import { RequisiteTypeEnum, requisiteTypesToTitlesMap } from 'modules/payments/enums/paymentRequisiteType.enum';

export function useRejectedTransactionsColumns(): ColumnType<RejectedTransaction>[] {
  return useMemo(
    () => [
      {
        title: 'Имя мерчанта',
        key: 'merchant.id',
        dataIndex: 'merchant',
        align: 'center',
        render: (merchant: RejectedTransactionMerchant) => <Typography.Text>{merchant?.name}</Typography.Text>,
      },
      {
        title: 'Номер заявки мерчанта',
        key: 'remoteOrderId',
        dataIndex: 'remoteOrderId',
        align: 'center',
      },
      {
        title: 'Сумма',
        dataIndex: 'amount',
        key: 'amount',
        align: 'center',
        width: '10px',
        render: (amount: string) => amount.toLocaleString() || '- - -',
      },
      {
        title: 'Тип реквизита',
        dataIndex: 'requisitesType',
        key: 'requisitesType',
        align: 'center',
        width: '10px',
        render: (requisiteType: RequisiteTypeEnum | undefined) => {
          if (!requisiteType) {
            return '- - -';
          }
          return <Typography.Text>{requisiteTypesToTitlesMap[requisiteType]}</Typography.Text>;
        },
      },
      {
        title: 'Банк',
        key: 'bankId',
        align: 'center',
        dataIndex: 'bank',
        render: (bank: RejectedTransactionBank) => <Typography.Text>{bank?.name}</Typography.Text>,
      },
      {
        title: 'Валюта',
        key: 'currencyCode',
        dataIndex: 'currencyCode',
        align: 'center',
      },
      {
        title: 'Дата и время создания (местное)',
        key: 'createdAt',
        dataIndex: 'createdAt',
        align: 'center',
        render: (createdAt: string) => {
          const localTime = dayjs.utc(createdAt).local().format(ORDER_DATETIME_STRING_FORMAT);
          return <Typography.Text>{localTime}</Typography.Text>;
        },
      },
    ],
    []
  );
}
