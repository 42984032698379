import React from 'react';
import { Button, Form, FormInstance } from 'antd';
import { FormFieldSchema } from './types/fieldsSchema.interface';
import { UnifiedFormItem } from './components/Item';

interface Props<T> {
  fields: FormFieldSchema<T>[];
  form: FormInstance<T>;
  onSubmitClick: () => void;
  submitButtonTitle?: string;
  isSubmitButtonAtBottom?: boolean;
  className?: string;
}

export const UnifiedForm = <T,>({
  fields,
  submitButtonTitle,
  className,
  onSubmitClick,
  isSubmitButtonAtBottom,
  form,
}: Props<T>) => {
  return (
    <Form className={className} form={form} onFinish={onSubmitClick}>
      {fields.map((schema) => (
        <UnifiedFormItem key={schema.name as string} schema={schema} />
      ))}
      <Button className={isSubmitButtonAtBottom ? 'mt-auto' : 'mt-4'} htmlType="submit" type="primary">
        {submitButtonTitle ?? 'Сохранить'}
      </Button>
    </Form>
  );
};
