import React, { useEffect } from 'react';

import { App, Drawer } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { useAppDispatch } from 'hooks/useAppDispatch.hook';

import { UnifiedForm } from 'components/UnifiedForm';
import { updateBankAccount } from 'modules/bankAccounts/store';
import { UpdateBankAccountParams } from 'modules/bankAccounts/store/types/updateBankAccountParams.interface';
import { BankAccount } from 'modules/bankAccounts/types/bankAccount.interface';
import { fieldsSchema } from './fields';

interface Props {
  bankAccount?: BankAccount;
  onClose: () => void;
}

export const BankAccountsListUpdateDrawer: React.FC<Props> = ({ bankAccount, onClose }) => {
  const [form] = useForm<UpdateBankAccountParams>();

  const { message } = App.useApp();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (bankAccount) {
      form.setFieldsValue({
        bic: bankAccount.bic,
        id: bankAccount.id,
        accountNumber: bankAccount.accountNumber,
        type: bankAccount.type.id,
        bankId: bankAccount.bank.id,
        phone: bankAccount.phone,
        limitMinIn: bankAccount.limitMinIn,
      });

      return;
    }

    form.resetFields();
  }, [bankAccount]);

  async function handleSubmitPress() {
    try {
      await dispatch(updateBankAccount(form.getFieldsValue())).unwrap();

      message.success('Счет владельца успешно обновлен');
      onClose();
    } catch (error) {
      message.error('Не удалось обновить счет владельца.');
    }
  }

  return (
    <Drawer open={!!bankAccount} title="Редактирование счёта" onClose={onClose}>
      <UnifiedForm
        form={form}
        onSubmitClick={handleSubmitPress}
        className="flex flex-col h-full"
        fields={fieldsSchema}
        isSubmitButtonAtBottom
      />
    </Drawer>
  );
};
